import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"

const query = graphql`
  {
    datoCmsHome {
      longDescription
      shortDescription
      image {
        fixed {
          ...GatsbyDatoCmsFixed
        }
      }
    }
  }
`

const Hero = () => {
  const data = useStaticQuery(query)
  const { datoCmsHome } = data

  const { shortDescription, image } = datoCmsHome
  return (
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="underline"></div>
            <h1>{shortDescription}</h1>
            <h3 style={{ textTransform: "none" }}>
              Professor of Software Engineering <br/> ECS-CAPI Chair in Inclusive Science, Technology and Engineering
            </h3>
            <h3 style={{ textTransform: "none" }}>
              University of Victoria, BC, Canada
            </h3>
            <SocialLinks />
          </div>
        </article>
        <Image fixed={image.fixed} className="hero-img" />
      </div>
    </header>
  )
}

export default Hero
